.background {
  background-image: url("Assets/golden-bg.jpg");
  /* min-height: 90vh; */
  /* position: relative; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - var(--footerHeight));
  /* border: 2px solid red; */
}
.container {
  max-width: 90% !important;
  padding: 0px !important;
}
