.background {
  background-image: url("Assets/golden-bg.jpg");
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}
.contentBackground {
  background-color: white;
  min-height: 80vh;
  padding-bottom: 20px;
}
.contentContainer {
  color: black !important;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}
.contentContainer h2 {
  color: black !important;
  text-align: center;
}
.contentContainer h3 {
  text-align: center;
}
