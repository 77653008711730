.background {
  background-image: url("Assets/golden-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  padding: 0% !important;
  max-width: 90% !important;
}
.contentBackground {
  min-height: calc(100vh - var(--navbarHeight) - var(--footerHeight));
  background-color: white;
  padding-bottom: 20px;
  z-index: -1000 !important;
}

.wrapper {
  text-align: center;
  padding-top: 20px;
}
