.main {
  background-image: url("Assets/golden-bg.jpg");
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  max-width: 100% !important;
}
.height {
  background-color: rgb(209, 206, 206);
  min-height: 71.2vh;
}
/* Admin table css */
.actionFlex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 400px;
}
.tableHead {
  text-decoration: unset !important;
}
.editFormFlex {
  display: flex;
  justify-content: flex-end;
}
.editFormFlex div {
  margin-right: 20px;
}
.nameRow {
  cursor: pointer;
  color: #0066cc !important;
}
.tableHeading {
  text-align: center;
  color: black;
}
/* EmbedVideo css */
.embedMain {
  border: 0.5px solid gray;
  border-radius: 10px;
  text-align: center;
  background-color: white;
}
.embedContainer {
  padding: 15px;
}
.embedHeading {
  color: black !important;
}
.embedForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.embedInput {
  width: 100% !important;
  border: 1px solid black !important;
  padding-left: 15px !important;
  color: black !important;
  height: 36px !important;
  border-radius: 5px !important;
}
.embedFlex1 {
  flex-basis: 80%;
}
.embedFlex2 {
}
.ssnHeading {
  color: black !important;
}

/* Ssn login css */
.form {
  text-align: center;
  min-height: 200px;
}
.form h1 {
}
.ssnInput {
  height: 30px !important;
  border: 1px solid black !important;
  border-radius: 10px !important;
  width: 90% !important;
  margin-top: 20px;
}
.ssnSubmit {
  margin-top: 20px !important;
}

@media screen and (max-width: 768px) {
  .embedFlex1 {
    flex-basis: 60%;
  }
}
@media screen and (max-width: 410px) {
  .embedFlex1 {
    flex-basis: 100%;
  }
  .embedFlex2 {
    flex-basis: 100%;
  }
  .embedForm {
    flex-wrap: wrap;
    justify-content: center;
  }
  .embedButton {
    margin-top: 10px !important;
    width: 100% !important;
  }
}
