.courseDetailsContainer {
  color: white;
  height: 100%;
  padding: 30px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseTitle {
  font-weight: bold;
  text-transform: uppercase;
}

.courseDescription {
  font-size: 13px;
}

.socialDiv img {
  width: 40px;
  border-radius: 5px;
}

.videoSize {
  width: 530px;
  height: 315px;
}

@media screen and (max-width: 992px) {
  .videoSize {
    width: 380px;
    height: 230px;
  }
}

@media screen and (max-width: 768px) {
  .videoSize {
    width: 330px;
    height: 200px;
  }
}
