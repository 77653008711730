.paymentTypeContainer {
  color: white;
  padding: 10px 10px 0px 10px;
}

.paymentTitle {
  font-size: 14px;
  text-transform: uppercase;
}

.PaymentApps {
  width: 60%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.col1 {
  display: flex;
  align-items: center;
}

.col1 input {
  height: 17px;
  width: 17px;
}

.col2 {
  text-align: left;
}

.col2 input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 0px 10px;
  outline: none;
}
.importantNoteText {
  font-size: 12px;
}

.submitBtn {
  background-color: #c00000;
  color: white;
}

.backdrop {
  z-index: 99999;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
