.TrainingContentContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
}

.conferenceImg {
  width: 100%;
  border-radius: 50%;
}
