.text {
  color: white;
  font-size: 14px;
  width: 100%;
}
.text > p {
  padding-right: 85px;
  padding-left: 85px;
}
.button button {
  border: 2px solid white;
  background-color: #bb0001;
  color: white;
  width: auto;
  font-size: 17px;
  font-weight: 550;
  border-radius: 10px;
  padding: 8px 10px;
  cursor: pointer;
}
.comingSoon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.commingSoonDiv {
  color: white !important;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 20px;
}
.commingSoonDiv > span:nth-child(2) {
  background-color: #c00000;
  padding: 8px 15px;
  border-radius: 5px;
}

.mobileImagesContainer {
  margin-top: 15px;
}

@media screen and (max-width: 280px) {
  .button button {
    border: 5px solid white;
    background-color: #bb0001;
    color: white;
    width: 100%;
    font-size: 14px;
    font-weight: 550;
    border-radius: 10px;
    cursor: pointer;
  }
}
