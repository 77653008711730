.main {
  background-image: url("Assets/golden-bg.jpg");
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  max-width: 100% !important;
}
.height {
  background-color: white;
  height: calc(100vh - var(--navbarHeight));
}

/* EditBonusModal table css */
.form {
  text-align: center;
}
.container {
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
}
.commision {
  margin-top: 10px;
  margin-bottom: 10px;
}
.crypto {
  margin-top: 10px;
  margin-bottom: 10px;
}
.crypto h2,
.commision h2,
.status h2 {
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-align: left;
}
.status {
  margin-top: 10px;
  margin-bottom: 10px;
}
.input {
  height: 50px !important;
  width: 100% !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 10px !important;
  padding-left: 15px !important;
}
.select {
  height: 50px !important;
  width: 100% !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 10px !important;
  padding-left: 15px !important;
}
.mainCards {
  text-align: center;
  background-color: white;
}
.mainCards h2 {
  color: black !important;
}
.mainCardsFlex {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* LicenceCard css */
.mainLicence {
  margin-bottom: 50px;
}
.licenceCard {
  border: 1px solid black;
  border-radius: 5px;
  min-height: 300px;
  background-color: white;
  width: 360px !important;
}
.licenceHeader {
  background-color: black;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.licenceHeader p {
  color: white !important;
  font-size: 20px !important;
  font-weight: 600;
}
.licenceBody {
  padding-top: 80px;
}
.licenceImage {
  padding-bottom: 20px;
}
.licenceDownload {
}
.paymentTitle {
  font-weight: bold;
  text-align: right;
}

/* BonusTable */
.bonusTable {
  min-width: 560px;
}
.centerItems {
  /* display: flex; */
  /* border: 2px solid red; */
  /* justify-content: center; */
  /* align-items: center; */
}

@media screen and (max-width: 1400px) {
  .licenceCard {
    width: 500px;
  }
}
@media screen and (max-width: 1180px) {
  .mainCardsFlex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .mainCardsFlex div {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .licenceCard {
    width: 300px;
    min-height: 200px;
  }
  .licenceBody {
    padding-top: 30px;
  }
}
@media screen and (max-width: 430px) {
  .licenceCard {
    width: 250px;
    min-height: 200px;
  }
  .licenceBody {
    padding-top: 30px;
  }
}
/* ContractCard css */
