/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback c#useolor */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #dedede;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 1px 8px;
  background-color: black;
  color: white;
  position: absolute;
  right: 10px;
  top: 5px;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.clearfix:after,
form:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.container {
  margin: 25px auto;
  position: relative;
  width: 900px;
}
#content {
  background: #f9f9f9;
  background: -moz-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -webkit-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -o-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -ms-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
  -webkit-box-shadow: 0 1px 0 #fff inset;
  -moz-box-shadow: 0 1px 0 #fff inset;
  -ms-box-shadow: 0 1px 0 #fff inset;
  -o-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
  border: 1px solid #c4c6ca;
  margin: 0 auto;
  padding: 25px 0 0;
  position: relative;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  width: 400px;
}
.dlButton {
  border: 2px solid black !important;
  border-radius: 6px !important;
  background-color: white !important;
}

.supportButton {
  border: 2px solid black !important;
  border-radius: 6px !important;
  background-color: white !important;
}
.dlButton:hover,
.supportButton:hover {
  background-color: lightgrey !important;
}
#content h1 {
  color: #7e7e7e;
  font: bold 25px Helvetica, Arial, sans-serif;
  letter-spacing: -0.05em;
  line-height: 20px;
  margin: 10px 0 30px;
}
#content h1:before,
#content h1:after {
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 27%;
}
#content h1:after {
  background: rgb(126, 126, 126);
  background: -moz-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  right: 0;
}
#content h1:before {
  background: rgb(126, 126, 126);
  background: -moz-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(126, 126, 126, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  left: 0;
}
#content:after,
#content:before {
  background: #f9f9f9;
  background: -moz-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -webkit-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -o-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: -ms-linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  background: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
  border: 1px solid #c4c6ca;
  content: "";
  display: block;
  height: 100%;
  left: -1px;
  position: absolute;
  width: 100%;
}
#content:after {
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  top: 0;
  z-index: -1;
}
#content:before {
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
  top: 0;
  z-index: -2;
}
#content form {
  margin: 0 20px;
  position: relative;
}
#content form input[type="text"],
#content form input[type="password"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -moz-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -ms-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #eae7e7 url(https://cssdeck.com/uploads/media/items/8/8bcLQqF.png)
    no-repeat;
  border: 1px solid #c8c8c8;
  color: #777;
  font: 13px Helvetica, Arial, sans-serif;
  margin: 0 0 10px;
  padding: 15px 10px 15px 40px;
  width: 80%;
}
#content form input[type="text"]:focus,
#content form input[type="password"]:focus {
  -webkit-box-shadow: 0 0 2px #ed1c24 inset;
  -moz-box-shadow: 0 0 2px #ed1c24 inset;
  -ms-box-shadow: 0 0 2px #ed1c24 inset;
  -o-box-shadow: 0 0 2px #ed1c24 inset;
  box-shadow: 0 0 2px #ed1c24 inset;
  background-color: #fff;
  border: 1px solid #ed1c24;
  outline: none;
}
#username {
  background-position: 10px 10px !important;
}
#email {
  background-position: 10px 10px !important;
}
#password {
  background-position: 10px -53px !important;
}
#confirmPassword {
  background-position: 10px -53px !important;
}
#content form input[type="submit"] {
  background: rgb(254, 231, 154);
  background: -moz-linear-gradient(
    to top,
    rgba(254, 231, 154, 1) 0%,
    rgba(254, 193, 81, 1) 100%
  );
  background: -webkit-linear-gradient(
    to top,
    rgba(254, 231, 154, 1) 0%,
    rgba(254, 193, 81, 1) 100%
  );
  background: -o-linear-gradient(
    to top,
    rgba(254, 231, 154, 1) 0%,
    rgba(254, 193, 81, 1) 100%
  );
  background: -ms-linear-gradient(
    to top,
    rgba(254, 231, 154, 1) 0%,
    rgba(254, 193, 81, 1) 100%
  );
  background: linear-gradient(
    to top,
    rgba(254, 231, 154, 1) 0%,
    rgba(254, 193, 81, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fee79a', endColorstr='#fec151',GradientType=0 );
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  -ms-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  -o-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  border: 1px solid #d69e31;
  color: #85592e;
  cursor: pointer;
  float: left;
  font: bold 15px Helvetica, Arial, sans-serif;
  height: 35px;
  margin: 20px 0 35px 15px;
  position: relative;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 120px;
}
#content form input[type="submit"]:hover {
  background: rgb(254, 193, 81);
  background: -moz-linear-gradient(
    to top,
    rgba(254, 193, 81, 1) 0%,
    rgba(254, 231, 154, 1) 100%
  );
  background: -webkit-linear-gradient(
    to top,
    rgba(254, 193, 81, 1) 0%,
    rgba(254, 231, 154, 1) 100%
  );
  background: -o-linear-gradient(
    to top,
    rgba(254, 193, 81, 1) 0%,
    rgba(254, 231, 154, 1) 100%
  );
  background: -ms-linear-gradient(
    to top,
    rgba(254, 193, 81, 1) 0%,
    rgba(254, 231, 154, 1) 100%
  );
  background: linear-gradient(
    to top,
    rgba(254, 193, 81, 1) 0%,
    rgba(254, 231, 154, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fec151', endColorstr='#fee79a',GradientType=0 );
}
#content form div a {
  color: #004a80;
  float: right;
  font-size: 12px;
  margin: 30px 15px 0 0;
  text-decoration: underline;
}
.button {
  background: rgb(247, 249, 250);
  background: -moz-linear-gradient(
    to top,
    rgba(247, 249, 250, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: -webkit-linear-gradient(
    to top,
    rgba(247, 249, 250, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: -o-linear-gradient(
    to top,
    rgba(247, 249, 250, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: -ms-linear-gradient(
    to top,
    rgba(247, 249, 250, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: linear-gradient(
    to top,
    rgba(247, 249, 250, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f9fa', endColorstr='#f0f0f0',GradientType=0 );
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #cfd5d9;
  padding: 15px 0;
}
.button a {
  background: url(https://cssdeck.com/uploads/media/items/8/8bcLQqF.png) 0 -112px
    no-repeat;
  color: #7e7e7e;
  font-size: 17px;
  padding: 2px 0 2px 40px;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.button a:hover {
  background-position: 0 -135px;
  color: #00aeef;
}
.xccess-logo {
  position: absolute;
  left: 10px;
  top: 0;
}
.spanLinkStyles {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}
@media (max-width: 600px) {
  #content {
    width: 327px;
  }

  .spanLinkStyles {
    font-size: 11px;
  }
}
