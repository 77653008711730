.main {
  background-color: white;
  height: var(--navbarHeight);
  display: flex;
  align-items: center;
  padding: 0px 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1000 !important;
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex1 .MyxfundLogo {
  width: 300px;
  cursor: pointer;
}

.flex2 .mobileButtonFlex {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 15px;
}

.flex2 .supportButtonMobile {
  border: 2px solid black !important;
  border-radius: 6px !important;
  background-color: white !important;
}

.flex2 .userAvatar {
  background-color: black;
  color: white;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
}

@media screen and (max-width: 700px) {
  .MyxfundLogo {
    width: 150px !important;
  }
}
