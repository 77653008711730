.chartContainer {
  padding: 40px 20px;
}

.referralButton h2 {
  display: inline;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.chartImage {
  margin-top: 30px;
}
.chartImage img {
  width: 100%;
}

@media (max-width: 992px) {
  .chartContainer {
    padding: 20px;
  }

  .referralButton h2 {
    font-size: 14px;
  }
}
