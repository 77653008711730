.name {
  color: white;
  padding-bottom: 30px;
}
.nameTitle {
  text-transform: capitalize !important;
}
.leftBar {
  color: white;
  font-size: 14px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.leftBar p {
  cursor: pointer;
}

.profilePicture img {
  margin-left: 70px;
}
.profilePicture h3 {
  float: right;
  font-weight: 400;
  text-decoration: underline;
}
.spanTextDesktop {
  position: relative;
  bottom: 70px;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}
.spanTextMobile {
  display: none;
}
.commissionDiv {
  margin-top: 0px;
}
.links {
  text-decoration: unset !important;
  color: white !important;
}
.links:hover {
  text-decoration: underline !important;
}
.links2Div {
  margin-top: 30px;
}
@media screen and (max-width: 360px) {
  .spanTextMobile {
    display: block;
    font-size: 14px;
    font-weight: 200;
    text-decoration: underline;
  }
  .spanTextDesktop {
    display: none;
  }
  .profilePicture img {
    margin-left: 0px;
  }
}
