.DigitalWalletContentContainer {
  color: white;
  padding: 10px 10px 0px 10px;
}

.infoText {
  background-color: white;
  color: black;
  font-size: 13px;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 10px;
}

.noteText {
  margin-top: 15px;
  padding: 0px 10px;
  border: 2px solid white;
  font-size: 12px;
}

.noteText span {
  color: red;
}

.inputFieldContainer {
  margin-top: 15px;
  text-align: left;
}

.inputFieldContainer label {
  font-size: 18px;
  text-align: left;
}

input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 0px 10px;
  outline: none;
}

.submitBtn {
  margin-top: 10px;
  /* background-color: #c00000;
  color: white; */
}
