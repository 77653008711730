.leftBar {
  margin-top: 40px;
  padding: 10px 10px 0px 10px;
  color: white;
}

.image {
  border-radius: 50%;
  width: 100%;
  margin-top: 20px;
}

.video {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.video2 {
  width: 100%;
  height: 250px;
  border-radius: 20px;
}

.box {
  border: 1px solid white;
  color: white;
  font-size: 30px;
  margin: 0px 30px 0px 30px !important;
  border-radius: 5px;
}
.text p {
  color: white;
  line-height: 12px;
}

.xracecars p {
  font-size: 18px;
}
.xracecars img {
  height: 50px;
}
