.wrapper {
  /* background-image: url("Assets/golden-bg.jpg");
  background-size: cover; */
  background-color: #c00000;
  width: 100%;
  height: auto;
}
.container {
  padding: 0px;
  max-width: 90%;
  margin: auto;
  box-sizing: border-box;
  background: black;
  height: 95.5%;
}

.heading {
  height: 10vh;
  padding-left: 20px;
  box-sizing: content-box;
  padding-top: 40px;
  text-align: center;
}
.heading > span {
  background-color: #c00000;
  padding: 6px 10px;
  font-size: 24px;
  color: white;
  border-radius: 5px;
}
.contentDiv {
  background-image: url("Assets/xcoin-poster.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  color: white;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 20px;
}

/* Accordion css */
.accordion {
  margin-bottom: 25px !important;
  border: 1px solid #c00000 !important;
}
.accordHeading {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.accordText {
  font-size: 16px !important;
  font-weight: 600 !important;
}

@media (max-width: 768px) {
  .container {
    padding: 0px;
    width: 100%;
  }
  .heading > span {
    font-size: 18px;
  }
  .accordHeading {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .accordText {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}
