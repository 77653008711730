.courseDetailsContainer {
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseTitle {
  font-weight: bold;
  text-transform: uppercase;
}

.downloadAnchor {
  text-decoration: none;
}

.downloadButton {
  color: black;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 15px;
  background: #ffc000;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
}
