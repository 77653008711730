.main {
  background-color: black;
  color: white;
  text-align: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
