.border {
  border: 1px solid black;
  background-color: white;
  text-transform: uppercase;
}
.header {
  color: white;
  background-color: black;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tick {
  /* float: left;
  margin-left: 10px; */
  /* padding-left: -25px; */
}
.bulletPoints {
  font-weight: 550;
}
.buttonFlex {
  display: flex;
  justify-content: center;
}
.warning {
  font-size: 12px;
  font-weight: 500;
  color: red;
}
.buttonFlex div {
  /* margin-left: 100px;
  margin-right: 100px; */
}
.submitButton {
  background-color: black !important;
  margin-top: 10px !important;
}
.buttonDiv {
  padding-bottom: 20px;
}
.button {
  background-color: #c10002 !important;
  margin-top: 10px !important;
}
@media screen and (max-width: 1280px) {
  .border {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
  }
  .button {
    background-color: #c10002 !important;
    margin-bottom: 10px !important;
  }
}
