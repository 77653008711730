.gridMain {
  min-height: calc(100vh - var(--navbarHeight) - var(--footerHeight));
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  width: 65%;
  margin: auto;
  text-align: center;
}

.border {
  border: 1px solid black;
  background-color: white;
  text-transform: uppercase;
  width: 68%;
  margin-left: auto;
  margin-right: auto;
  min-height: 250px;
  text-align: center;
}
.header {
  color: white;
  background-color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.tick {
  /* float: mid;
  margin-left: 10px; */
}
.text {
  font-size: 26px;
  line-height: 40px;
  font-weight: 500;
}
.bulletPoints {
  text-decoration: underline;
  font-weight: 550;
}
.buttonFlex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 40px;
}
.fileName {
  font-weight: 600;
}
.button {
  background-color: #c10002 !important;
  margin-top: 10px !important;
  /* margin-left: 85px !important;
  margin-right: 85px !important; */
}
.downloadAnchor {
  text-decoration: none !important;
  color: black !important;
}
.downloadAnchor:hover {
  color: blue !important;
}

@media screen and (max-width: 1280px) {
  .border {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
  }
  .button {
    background-color: #c10002 !important;
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .border {
    width: 90%;
  }
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
  }
  .text {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
  }
  .main {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .text {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
  }
}
