.background {
  background-image: url("Assets/golden-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - var(--navbarHeight) - var(--footerHeight));
}
.container {
  /* max-width: 88% !important; */
}
.height {
  background-color: white;
  /* min-height: 70vh; */
}
