.heroContainer {
  /* background-image: url("Assets/golden-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #c00000;
  min-height: 100vh;
}
.mainDiv {
  max-width: 90%;
  margin: auto;
}

.contentDiv {
  background-image: url("Assets/hero-black-bg.jpg");
  min-height: calc(100vh - var(--navbarHeight));
  color: white;
  padding-bottom: 30px;
}

.rewardHeaderDiv {
  border: 1px solid black;
  height: 120px;
  border-bottom: 5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rewardHeaderDiv .logoDiv {
  text-align: center;
  position: relative;
}
.rewardHeaderDiv .logoDiv img {
  width: 300px;
}
.rewardHeaderDiv .logoDiv p {
  position: absolute;
  bottom: -10px;
  right: 25px;
  font-size: 25px;
  color: #ffc000;
  font-weight: 600;
}
.rewardHeaderDiv .logoDiv p span {
  color: #c00000;
}

.rewardHeaderDiv .rewardText p {
  font-size: 30px;
  color: #4c69a7;
  font-weight: 500;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  padding: 20px 80px;
  padding-bottom: 15px;
}

.rewardPlanCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid white;
  padding: 20px;
}

.listSinglePoint {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.listSinglePoint div {
  height: 100%;
}
.listSinglePoint img {
  margin-right: 5px;
}
.listSinglePoint p {
  font-size: 14px;
  margin: 0;
}

.rewardButton {
  text-align: center;
}
.rewardButton p {
  font-weight: 600;
  margin: 0px;
  background-color: white;
  color: black;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 15px;
}

.earlySupporterButton {
  text-align: center;
  margin-bottom: 15px;
}
.earlySupporterButton button {
  background-color: rgb(109, 118, 168);
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}
.buyButton p {
  background-color: #c00000;
  color: white;
  cursor: pointer;
}

.rewardPrice {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin: 5px;
}

.heroVideo {
  text-align: center;
}
.videoSize {
  width: 620px;
  height: 350px;
}

.plusText {
  margin-top: 10px !important;
  font-size: 20px !important;
  font-weight: 500;
  text-align: center;
}

.xBlockLink {
  color: white;
  font-weight: 500;
}

.coloredValue {
  color: #ffc000;
  font-weight: 600;
}
.coloredValue span {
  color: #c00000;
}

.xracecars {
  padding: 0px 80px;
  text-align: center;
}
.xracecars p {
  font-size: 26px;
}
.xracecars img {
  height: 70px;
  vertical-align: middle;
}

.bottomNote p {
  width: 50%;
  margin: auto;
  border: 3px solid white;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 1200px) {
  .cardsContainer {
    grid-template-columns: 1fr;
  }
  .videoSize {
    width: 520px;
    height: 350px;
  }

  .cardsContainer {
    padding: 20px;
  }

  .xracecars {
    padding: 0px 20px;
  }
  .xracecars p {
    font-size: 20px;
  }
  .xracecars img {
    height: 50px;
    vertical-align: middle;
  }
}
@media screen and (max-width: 992px) {
  .bottomNote {
    padding: 20px;
  }
  .bottomNote p {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .cardsContainer {
    grid-template-columns: 1fr;
  }

  .rewardHeaderDiv .logoDiv img {
    width: 150px;
  }
  .rewardHeaderDiv .logoDiv p {
    bottom: -5px;
    right: 10px;
    font-size: 12px;
    color: #ffc000;
    font-weight: 600;
  }

  .rewardHeaderDiv .rewardText p {
    font-size: 20px;
  }

  .videoSize {
    width: 280px;
    height: 200px;
  }
}
