.formInline label {
  font-size: 13px;
  font-weight: 550;
}
.formInline {
  margin-top: 10px;
}
.ahContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.ahContainer p {
  font-size: 10px;
}
.largeInput {
  background-color: #fff;
  width: auto;
  border: 1px solid rgb(70, 70, 70) !important;
  height: 25px !important;
}

.flex {
  display: flex;
  align-items: center;
}
.flex2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex2 div {
  margin-top: 15px;
}
.flex input,
.flex2 input {
  flex: 2;
  height: 20px;
  border: 2px solid black;
  border-radius: 6px;
}

.flex input,
label {
  margin: 1px;
}
.button {
  background-color: #c10002 !important;
}
.note span {
  font-weight: 700;
  font-size: 17px;
}
.note {
  font-size: 13px;
  font-weight: 520;
}
.center {
  text-align: center !important;
}
.center img {
  padding-right: 8px;
}
.heading1 {
  font-weight: 800;
}
.heading2 {
  font-weight: 900;
  font-size: 18px;
}
.taxText {
  font-size: 14px;
  font-weight: 500;
}
.mobileLabel {
  display: none;
}
@media screen and (max-width: 390px) {
  .desktopLabel {
    display: none;
  }
  .mobileLabel {
    display: block;
  }
  .formInline label {
    font-size: 12px;
    font-weight: 550;
    white-space: pre-wrap;
  }
}
@media screen and (max-width: 340px) {
  .largeInput {
    width: 120px;
  }
}
@media screen and (max-width: 340px) {
  .largeInput {
    width: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .flex input,
  label {
    margin: 5px;
  }
  .flex input {
    flex: 2;
    height: 30px;
    border: 2px solid black;
    border-radius: 6px;
  }
  .formInline {
    padding: 20px;
  }
}
