.cardFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cardMain {
  height: auto;
  width: 270px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin-bottom: 20px;
}
.cardContainer {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}
.cardContainer h2 {
  color: black !important;
  font-size: 16px;
  line-height: 20px;
}
.cardContainer h3 {
  font-size: 15px;
  line-height: 20px;
  margin-top: -5px;
}
.cardContainer p {
  font-size: 10px;
}
.textMainDiv {
  text-align: center;
}
.impTextDiv {
  color: black !important;
}
.impText {
  color: black !important;
  font-size: 1rem;
  font-weight: 500;
}
.impText span {
  font-size: 24px;
  font-weight: bold;
}

.list {
  font-size: 11px;
}
